import React from 'react'
import Img from "../components/image-transform"

const Meet = ({ content }) => {
  return (
    <div className="container">
      <div className="section-meet">
        <h2 className="title">Meet Janet and David</h2>
        <div className="comparison-block">
          {
            content.comparisons.map((comparison, i) => {
              return (
                <div className="comparison-item" key={i}>
                  {
                    comparison.image &&
                    comparison.svg ?
                    <img src={comparison.image} className="image" alt={comparison.imageName}/>
                    :
                    <Img filename={comparison.image} className="image" alt={comparison.imageName}/>
                  }
                  <p className="meet-text">{comparison.text}</p>
                </div>
              )
            })
          }
        </div>
        {
            content.parag.map((p, i) => {
              return (
                <p className="meet-paragraph" key={i}>{p.paragraph}</p>
              )
            })
        }
        <div className="outcome-block">
          {
            content.comparisonOutcomes.map((outcome, i) => {
              return (
                <div className="outcome-item" key={i}>
                  {
                    outcome.image &&
                    outcome.svg ?
                    <img src={outcome.image} className="image" alt={outcome.image}/>
                    :
                    <Img filename={outcome.image} className="image" alt={outcome.image}/>
                  }
                  <div className="score"> {outcome.score} </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Meet
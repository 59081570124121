import React from 'react'
import Img from "../components/image-transform"

const ValueScore = ({ content }) => {
  return (
    <div className="container">
      <div className="value-score">
        <h2 className="title">{content.title}</h2>
        {
          content.descriptions.map((desc, i) => {
            return (
              <p className="score-text">{desc.text}</p>
            )
          })
        }
        <div 
          className="main-block"
        >
        {
          content.image &&
          content.svg ?
          <img src={content.image} className="image" alt={content.image}/>
          :
          <Img filename={content.image} className="image" alt={content.image}/>
        }
          {/* <img src={content.image} srcSet={content.srcSet ? content.srcSet : ''} className="image" alt={content.content}/> */}
        </div>
      </div>
    </div>
  )
}

export default ValueScore